import { Locale } from "~/utils/interfaces/Locale";

export interface User {
  id?: string;
  email: string;
  first_name: string;
  second_name: string | null;
  last_name: string;
  second_last_name: string | null;
  phone: string;
  rut?: string;
  date_of_birth: string;
  gender: "male" | "female" | "other";
  full_name?: string;
  country?: string;
  document_type?: string;
  document_number?: string;
}

export interface NewNurse extends User {
  pcr: boolean;
  blood: boolean;
  comunas: string[];
  fingerprint_available: boolean;
  specialties: string[];
}

export const baseUser: User = {
  email: "",
  first_name: "",
  second_name: "",
  last_name: "",
  second_last_name: "",
  phone: "",
  document_type: "ci",
  document_number: "",
  date_of_birth: "",
  gender: "male",
  // country: "('cl', 'Chile')",
};

export interface ICreateUserWoPasswordPayload {
  first_name: string;
  second_name: string;
  last_name: string;
  second_last_name: string;
  document_type: "ci" | "passport" | "generic_id";
  document_number: string;
  date_of_birth: string;
  email: string;
  gender: "male" | "female";
  phone: string;
  locale: Locale;
  acquisition_source: string;
}

export interface ICreateUserWoPasswordResponse {
  msg: string;
  id: string;
}
