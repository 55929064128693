import { AxiosResponse } from "axios";

import api from "~/utils/api/api";
import { AddServicePayload } from "~/utils/interfaces/AppointmentPatient";

class PatientService {
  deactivate = async (appointmentPatientId: string) => {
    const data = {
      active: false,
    };
    return api.patch(`appointment-patients/${appointmentPatientId}/`, data);
  };

  activate = async (appointmentPatientId: string) => {
    const data = {
      active: true,
    };
    return api.patch(`appointment-patients/${appointmentPatientId}/`, data);
  };

  addService = async (
    appointmentPatientId: string,
    labServiceId: string,
    isFonasa: boolean,
  ): Promise<AxiosResponse<AddServicePayload>> => {
    const data = {
      labservice_id: labServiceId,
      is_fonasa: isFonasa,
    };
    return api.post<AddServicePayload>(`appointment-patients/${appointmentPatientId}/add_service/`, data);
  };

  fetchPatientAppointments = async (id: string): Promise<AxiosResponse<any>> => {
    return api.get(`patients/${id}/appointments/`);
  };

  detailsByIdentifier = async (identifier: string): Promise<AxiosResponse<any>> => {
    return api.get(`users/details_by_identifier/`, { params: { identifier } });
  };
}

const patientService = new PatientService();

export default patientService;
