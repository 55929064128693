import { monthNames } from "~/utils/dates/names";

export const formatDate = (dateStr: string): string => {
  const date = new Date(dateStr);
  const day: number = date.getDate();
  const year: number = date.getFullYear();
  const monthNumber: number = date.getMonth();
  const month: string = monthNames[monthNumber];

  return `${day} de ${month} de ${year}`;
};

export const formatDayDateHour = (dateStr: string): string => {
  const date = new Date(dateStr);
  const dayOfWeek = date.toLocaleString("es-CL", { weekday: "long" });
  const day: number = date.getDate();
  const year: number = date.getFullYear();
  const monthNumber: number = date.getMonth();
  const month: string = monthNames[monthNumber];
  const hoursAndMinutes = date.getHours() + ":" + String(date.getMinutes()).padStart(2, "0");

  return `${dayOfWeek} ${day} de ${month} ${year} - ${hoursAndMinutes}`;
};

export const calculateAge = (birth: string): number => {
  const birthDate = new Date(birth);
  const diff_ms = Date.now() - birthDate.getTime();
  const age_dt = new Date(diff_ms);

  return Math.abs(age_dt.getUTCFullYear() - 1970);
};

export function getDayName(strDate: string, locale: string) {
  const date = new Date(strDate);
  return date.toLocaleDateString(locale, { weekday: "long", timeZone: "UTC" }).toLowerCase();
}
